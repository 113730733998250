body {
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace
}

* {
	box-sizing: border-box
}

*,
body {
	margin: 0;
	padding: 0
}

body {
	font-family: Roboto, sans-serif;
	background: #fff;
	-webkit-text-size-adjust: 100%;
	font-weight: 400;
	color: #444;
	height: 100%;
	width: 100%;
	font-size: 13px;
	position: absolute;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

a {
	color: #1e90ff
}

a:hover {
	color: #0583ff
}

p.big {
	font-size: 18px;
	font-weight: 300
}

p.muted {
	font-size: 13px;
	color: #676767
}

h3 {
	font-size: 18px;
	color: #182026
}

h3,
h4 {
	font-weight: 600;
	padding: 0;
	margin: 0
}

h4 {
	color: #484848
}

h4,
h5 {
	font-size: 15px
}

h5 {
	color: #182026;
	margin: 5px 0
}

.bp3-menu-header>h6,
h6 {
	font-size: 13px;
	font-weight: 500
}

* {
	outline: none!important
}

hr {
	height: 1px;
	float: left;
	width: 100%;
	background: #eeeff0;
	display: block;
	border: none
}

.z-index {
	position: relative;
	z-index: 999
}

.bp3-fleft {
	float: left
}

.bp3-fright {
	float: right
}

.page {
	width: 100%;
	float: left;
	clear: both
}

.nowrap {
	white-space: nowrap
}

.bp3-file-upload-input,
.bp3-input,
.bp3-input.bp3-active,
.bp3-textarea,
.bp3-textarea.bp3-active,
input,
textarea {
	outline: none;
	box-shadow: none;
	border: 1px solid #cecfd0
}

.bp3-input[readonly] {
	box-shadow: none
}

.dollar-input {
	position: relative
}

.dollar-input:before {
	background: #f9f9f9;
	border: 1px solid #cecfd0;
	border-right: none;
	height: 34px;
	width: 25px;
	text-align: center;
	border-radius: 3px 0 0 3px;
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #676767;
	font-family: Icons16, sans-serif;
	content: "$"
}

.dollar-input .bp3-input-group {
	padding-left: 25px
}

.dollar-input .bp3-input-group .bp3-input {
	border-radius: 0 3px 3px 0
}

.bp3-file-upload-input:focus,
.bp3-file-upload-input:hover,
.bp3-input.bp3-active,
.bp3-input:focus,
.bp3-input:hover,
.bp3-textarea.bp3-active,
.bp3-textarea:focus,
.bp3-textarea:hover,
input:focus,
input:hover,
textarea:focus,
textarea:hover {
	outline: none;
	box-shadow: none;
	border: 1px solid #1e90ff
}

.inline-radio-buttons {
	padding: 10px 0 0
}

.inline-radio-buttons .bp3-control {
	margin: 0 20px 0 0;
	float: left
}

.bp3-control-group .Select-control {
	border-radius: 3px 3px 0 0!important
}

.bp3-control-group .bp3-input-group .bp3-input:focus,
.bp3-control-group .bp3-input-group .bp3-input:hover {
	z-index: 9
}

.dashboardCarrier {
	display: flex;
	flex-direction: row
}

.sidebar {
	position: relative;
	z-index: 9999;
	width: 240px;
	background: #eee;
	height: 100vh;
	background-color: #141414;
	box-shadow: 0 0 15px rgba(0, 0, 0, .05);
	padding: 35px
}

.sidebar .logo {
	position: relative;
	left: -8px;
	opacity: .9
}

input,
textarea {
	background-color: #fff;
	text-align: left;
	border: 1px solid #e1e1e1;
	font-size: 15px;
	margin: 0;
	padding: 8px 10px;
	display: block;
	background-clip: padding-box;
	border-radius: 3px;
	width: 300px
}

.label {
	font-size: 15px;
	line-height: 14px;
	font-weight: 700;
	color: #323232;
	margin: 10px 0 5px
}

.labelDesc {
	opacity: .7;
	font-size: 13px;
	margin-top: 5px
}

.bakiye {
	width: 120px;
	color: #52ba1a;
	font-weight: 700;
	height: 50px;
	background: #fff;
	text-align: center;
	line-height: 50px;
	border-radius: 6px;
	box-shadow: 0 0 15px rgba(0, 0, 0, .05);
	margin-bottom: 20px
}

.partner {
	display: block;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 2px;
	margin: 25px 0 0;
	color: hsla(0, 0%, 100%, .65);
	position: relative
}

.enterance {
	padding: 0 0 60px;
	border-bottom: 1px solid hsla(0, 0%, 100%, .15)
}

.nav {
	padding: 8px 0
}

.nav>div,
.navbar-group>div {
	font-size: 14px;
	font-weight: 400;
	padding: 10px 0;
	text-decoration: none;
	white-space: nowrap;
	cursor: pointer;
	color: hsla(0, 0%, 100%, .7)
}

.nav .active {
	font-weight: 700;
	color: #fff
}

.seperator {
	padding: 10px 0 0;
	margin: 10px 0 0;
	border-top: 1px solid hsla(0, 0%, 100%, .15)
}

.navbar-group {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 240px;
	padding: 7px 30px;
	color: hsla(0, 0%, 100%, .65);
	background: #101010;
	border-top: 1px solid hsla(0, 0%, 100%, .25)
}

.button {
	font-size: 15px;
	padding: 0;
	float: left;
	color: #fff;
	background: #141414;
	letter-spacing: .3px;
	background-clip: padding-box;
	border-radius: 4px;
	text-decoration: none;
	font-weight: 700;
	padding: 10px 15px!important;
	margin: 20px 0;
	cursor: pointer;
	box-shadow: 0 0 15px rgba(0, 0, 0, .05)
}

.navbar-group>svg {
	position: absolute;
	bottom: 15px;
	right: 20px;
	width: 20px
}

.dashboardRight {
	width: calc(100% - 240px);
	overflow-y: auto;
	overflow-x: hidden;
	height: 100vh
}

.sidebarClosed {
	width: 100%
}

.dashboardHeader {
	width: 100%;
	padding: 8px 20px;
	left: 0;
	display: flex;
	align-items: center;
	z-index: 10;
	background: #fcfcfc;
	border-bottom: 1px solid #eeeff0;
	box-shadow: 0 0 15px rgba(0, 0, 0, .05);
	height: 67px;
	position: relative
}

.dashboardHeader img {
	height: 20px;
	opacity: .6;
	margin-right: 15px;
	float: left;
	border-right: 1px solid rgba(0, 0, 0, .1);
	padding: 5px 16px 4px 0
}

.dashboardHeader .title {
	font-weight: 600;
	font-size: 15px
}

.dashboardHeader img:hover {
	opacity: 1;
	cursor: pointer
}

.dashboardContent {
	width: 100%;
	padding: 30px;
	font-size: 15px
}

.dashboardContent p {
	padding-bottom: 20px
}

.dashboardContent h2 {
	font-weight: 300;
	padding: 20px 0
}

.dashboardContent table {
	width: 100%;
	background: #fafafa;
	border: 1px solid #eee
}

.dashboardContent table tr:nth-child(2n) {
	background: #fff
}

.dashboardContent table td {
	padding: 10px
}

.dashboardContent table th {
	text-align: left;
	padding: 10px
}

.dashboardContent table a {
	text-align: center;
	text-decoration: none
}

.dashboardDiv {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px
}

.dashboardDiv>div {
	width: 25%;
	display: flex;
	flex-direction: row;
	align-items: center
}

.dashboardDiv>div>div {
	display: flex;
	flex-direction: column;
	justify-content: flex-start
}

.dashboardDiv>div>div>span {
	font-size: 20px;
	font-weight: 700
}

.dashboardDiv>div>img {
	width: 50px;
	height: 50px;
	padding: 15px;
	border-radius: 100%;
	background: #eee;
	margin-right: 20px
}

.panelTop {
	width: 100%;
	height: 25vh;
	margin-bottom: 30px;
	background: #141414;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column
}

.registerExplain {
	width: 300px;
	line-height: 1.5em;
	opacity: .8
}

.nonExisting,
.notification {
	background: #fafafa;
	border: 1px solid #eee;
	padding: 20px;
	text-align: center;
	color: rgba(0, 0, 0, .5);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center
}

.notification.error {
	background: #f5d9de
}

.notification.success {
	background: #d9f5d9
}

.nonExisting img {
	width: 30px;
	height: 30px;
	margin-bottom: 20px;
	-webkit-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite
}

.addNewDomain {
	display: flex;
	flex-direction: row;
}

.domains p {
	border-bottom: 1px solid #eee;
	font-size: 1.1em;
	display: flex;
	flex-direction: row;
	padding-bottom: 10px;
	padding-top: 5px;
	align-items: center;
}

p .remove {
	display: none;
	color: red;
	font-size: 0.8em;
	font-weight: bold;
	cursor: pointer;
	margin-left: 10px;
}

p:hover .remove {
	display: block;
}

.addNewDomain .button {
	margin: 0px;
	margin-left: 10px;
}

.plans {
	display: flex;
	flex-direction: row;
}

.plans b {
	margin-bottom: 5px;
	font-size: 1.1em;
}

.plans p {
	width: 30%;
	margin: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.plans li {
	padding: 0px;
	margin: 0px;
	list-style: none;
}

.plans .button {
	text-align: center;
}

.bill {
	font-family: monospace;
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #eee;
	background: #fafafa;
}

.code {
	font-family: monospace;
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #eee;
	background: #fafafa;
}


@-webkit-keyframes rotating {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn)
	}
}

@keyframes rotating {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn)
	}
}
